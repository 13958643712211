<template>
  <div class="user-management">
    <el-card>
      <div slot="header">
        <span>用户管理</span>
        <el-button 
          type="primary" 
          size="small" 
          @click="dialogVisible = true"
          style="float: right">
          新增用户
        </el-button>
      </div>

      <el-table
        v-loading="storeLoading"
        :data="users"
        style="width: 100%">
        <el-table-column prop="accountName" label="用户名"></el-table-column>
        <el-table-column prop="createTime" label="创建时间">
          <template slot-scope="scope">
            {{ formatDate(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="whetherAdmin" label="是否管理员">
          <template slot-scope="scope">
            {{ scope.row.whetherAdmin === 1 ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="handleGetPassword(scope.row)">
              获取密码
            </el-button>
            <el-button
              type="text"
              size="small"
              class="delete-btn"
              @click="handleDelete(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <!-- 新增用户对话框 -->
    <el-dialog
      title="新增用户"
      :visible.sync="dialogVisible"
      width="30%"
      @close="resetForm">
      <el-form :model="form" :rules="rules" ref="form" label-width="80px">
        <el-form-item label="姓名" prop="fullName">
          <el-input v-model.trim="form.fullName" placeholder="请输入姓名"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'UserManagement',
    data() {
      return {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        dialogVisible: false,
        submitting: false,
        form: {
          fullName: ''
        },
        rules: {
          fullName: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
          ]
        }
      }
    },
    computed: {
      users() {
        return this.$store.state.userManagement.users
      },
      storeLoading() {
        return this.$store.state.userManagement.loading
      },
      storeTotal() {
        return this.$store.state.userManagement.total
      }
    },
    methods: {
      formatDate(date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss')
      },
      async fetchData() {
        try {
          const { total } = await this.$store.dispatch('userManagement/fetchUsers', {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          })
          this.total = total
        } catch (error) {
          this.$message.error(error.toString())
        }
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.pageNum = 1  // 切换每页显示数量时重置为第一页
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.fetchData()
      },
      async handleDelete(row) {
        try {
          await this.$confirm('确认删除该用户吗？', '提示', {
            type: 'warning'
          });

          await this.$store.dispatch('userManagement/deleteUser', row.accountName);
          this.$message.success('删除成功');

          // 如果当前页只有一条数据且不是第一页，删除后自动跳转到上一页
          if (this.users.length === 1 && this.pageNum > 1) {
            this.pageNum--;
          }
          await this.fetchData();
        } catch (error) {
          if (error !== 'cancel') {
            this.$message.error(error.toString());
          }
        }
      },
      resetForm() {
        this.$refs.form?.resetFields()
        this.form.fullName = ''
      },
      async handleSubmit() {
        try {
          await this.$refs.form.validate()
          this.submitting = true

          await this.$store.dispatch('userManagement/createUser', {
            fullName: this.form.fullName
          })

          this.$message.success('创建成功')
          this.dialogVisible = false
          this.resetForm()
          // 创建成功后重置到第一页
          this.pageNum = 1
          await this.fetchData()
        } catch (error) {
          this.$message.error(error.toString())
        } finally {
          this.submitting = false
        }
      },
      async handleGetPassword(row) {
        try {
          const password = await this.$store.dispatch(
            'userManagement/getPassword',
            row.accountName
          );

          // 使用 Element UI 的 MessageBox 显示密码
          await this.$alert(password, '用户密码', {
            confirmButtonText: '确定',
            callback: () => {
              // 可以在这里添加复制到剪贴板的功能
            }
          });
        } catch (error) {
          this.$message.error(error.toString());
        }
      },
    },
    created() {
      this.fetchData()
    },
  }
</script>

<style scoped>
  .pagination-container {
    margin-top: 20px;
    text-align: right;
  }

  .delete-btn {
    color: #F56C6C;
  }

  .el-button+.el-button {
    margin-left: 10px;
  }
</style> 