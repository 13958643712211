<template>
  <div class="data-groups">
    <el-card>
      <div slot="header">
        <span>数据组管理</span>
        <el-button 
          type="primary" 
          size="small" 
          @click="dialogVisible = true"
          style="float: right">
          新增数据组
        </el-button>
      </div>

      <el-table
        v-loading="storeLoading"
        :data="dataGroups"
        style="width: 100%">
        <el-table-column prop="sid" label="SID"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180">
          <template slot-scope="scope">
            {{ formatDate(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="creator" label="创建人"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="handleDelete(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <!-- 新增数据组对话框 -->
    <el-dialog
      title="新增数据组"
      :visible.sync="dialogVisible"
      width="30%"
      @close="resetForm">
      <el-form :model="form" :rules="rules" ref="form" label-width="80px">
        <el-form-item label="SID" prop="sid">
          <el-input v-model.trim="form.sid" placeholder="请输入SID"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'DataGroups',
    data() {
      return {
        dialogVisible: false,
        submitting: false,
        pageNum: 1,
        pageSize: 10,
        total: 0,
        form: {
          sid: ''
        },
        rules: {
          sid: [
            { required: true, message: '请输入SID', trigger: 'blur' },
            { pattern: /^\d+$/, message: 'SID必须为数字', trigger: 'blur' }
          ]
        }
      }
    },
    computed: {
      dataGroups() {
        return this.$store.state.dataGroups.dataGroups
      },
      storeLoading() {
        return this.$store.state.dataGroups.loading
      },
      storeTotal() {
        return this.$store.state.dataGroups.total
      }
    },
    methods: {
      formatDate(date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss')
      },
      async fetchData() {
        try {
          const { total } = await this.$store.dispatch('dataGroups/fetchDataGroups', {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          })
          this.total = total
        } catch (error) {
          this.$message.error(error.toString())
        }
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.pageNum = 1  // 切换每页显示数量时重置为第一页
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.fetchData()
      },
      resetForm() {
        this.$refs.form?.resetFields()
        this.form.sid = ''
      },
      async handleSubmit() {
        try {
          await this.$refs.form.validate()
          this.submitting = true

          await this.$store.dispatch('dataGroups/createDataGroup', {
            sid: this.form.sid
          })

          this.$message.success('创建成功')
          this.dialogVisible = false
          this.resetForm()
          // 创建成功后重置到第一页
          this.pageNum = 1
          await this.fetchData()
        } catch (error) {
          this.$message.error(error.toString())
        } finally {
          this.submitting = false
        }
      },
      async handleDelete(row) {
        try {
          await this.$confirm('确认删除该数据组吗？', '提示', {
            type: 'warning'
          })

          await this.$store.dispatch('dataGroups/deleteDataGroup', row.sid)
          this.$message.success('删除成功')
          // 如果当前页只有一条数据且不是第一页，删除后自动跳转到上一页
          if (this.dataGroups.length === 1 && this.pageNum > 1) {
            this.pageNum--
          }
          await this.fetchData()
        } catch (error) {
          if (error !== 'cancel') {
            this.$message.error(error.toString())
          }
        }
      }
    },
    created() {
      this.fetchData()
    }
  }
</script>

<style scoped>
  .pagination-container {
    margin-top: 20px;
    text-align: right;
  }
</style> 