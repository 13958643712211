<template>
  <div class="combined-dashboard">
    <el-card>
      <div slot="header" class="header">
        <span>数据关联分析</span>
        <div class="header-controls">
          <el-select
            v-model="searchQuery"
            multiple
            filterable
            placeholder="请选择SID"
            style="width: 220px; margin-right: 15px"
            collapse-tags
          >
            <el-option
              v-for="item in sidOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select
            v-model="belongToQuery"
            placeholder="选择归属"
            clearable
            multiple
            v-if="isAdmin"
            collapse-tags
            style="width: 220px; margin-right: 15px"
          >
            <el-option
              v-for="item in belongToOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="handleDateChange"
          ></el-date-picker>
        </div>
      </div>
      
      <div class="summary-section">
        <el-row :gutter="10">
          <el-col :span="6">
            <div class="summary-item">
              <span class="summary-label">总花费</span>
              <span class="summary-value cost">${{ (summaryData.totalSpeed || 0).toFixed(2) }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="summary-item">
              <span class="summary-label">总收入</span>
              <span class="summary-value revenue">${{ (summaryData.totalIncome || 0).toFixed(2) }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="summary-item">
              <span class="summary-label">平均ROI</span>
              <span class="summary-value roi">{{ summaryData.avgROI || '0' }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="summary-item update-time">
              <span class="summary-label">更新时间</span>
              <span class="summary-value time">{{ summaryData.updateTime || '-' }}</span>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-table
        v-loading="loading"
        :data="filteredTableData"
        style="width: 100%; margin-top: 15px"
        border
        @sort-change="handleSortChange">
        <!-- 基本信息列 -->
        <el-table-column label="基本信息">
          <el-table-column prop="seriesName" label="系列名称" width="180">
          </el-table-column>
          <el-table-column prop="sid" label="SID" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.sid }}</span>
            </template>
          </el-table-column>
           <el-table-column prop="styleName" label="广告位" width="120">
          </el-table-column>
          <el-table-column v-if="isAdmin" prop="belongTo" label="归属" width="100">
          </el-table-column>
          <el-table-column prop="date" label="日期" width="120">
            <template slot-scope="scope">
              {{ formatDate(scope.row.date) }}
            </template>
          </el-table-column>
        </el-table-column>

        <!-- TT投放数据列 -->
        <el-table-column label="TT投放数据">
          <template slot="header" slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <span>TT投放数据</span>
              <el-button
                type="text"
                @click="toggleTTColumns"
                style="margin-left: 5px"
              >
                <i :class="{ 'el-icon-arrow-down': !showAllTTColumns, 'el-icon-arrow-right': showAllTTColumns }"></i>
              </el-button>
            </div>
          </template>
          <!-- 默认显示的列 -->
          <el-table-column prop="ttCost" label="花费" width="80" sortable class-name="highlight-column">
            <template slot-scope="scope">
              <span class="bold">${{ scope.row.ttCost.toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="ttImpressions" label="展示次数" width="110" sortable></el-table-column>
          <el-table-column prop="ttConversions" label="转化次数" width="110" sortable></el-table-column>
          <el-table-column prop="ttCpa" label="转化费用" width="110" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.ttCpa.toFixed(2) }}</span>
              </template>
            </el-table-column>
          
          <!-- 可展开的列 -->
          <template v-if="showAllTTColumns">
            <el-table-column prop="ttClicks" label="点击次数" width="110" sortable></el-table-column>
            <el-table-column prop="ttCpm" label="千次展示费用" width="130" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.ttCpm.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ttCpc" label="点击费用" width="110" sortable>
              <template slot-scope="scope">
                <span>${{ scope.row.ttCpc.toFixed(2) }}</span>
              </template>
            </el-table-column>
            
            <el-table-column prop="ttCtr" label="点击率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.ttCtr }}%
              </template>
            </el-table-column>
            <el-table-column prop="ttCvr" label="转化率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.ttCvr }}%
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- Google变现数据列 -->
        <el-table-column label="Google变现数据">
          <template slot="header" slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <span>Google变现数据</span>
              <el-button
                type="text"
                @click="toggleGoogleColumns"
                style="margin-left: 5px"
              >
                <i :class="{ 'el-icon-arrow-down': !showAllGoogleColumns, 'el-icon-arrow-right': showAllGoogleColumns }"></i>
              </el-button>
            </div>
          </template>
          <!-- 默认显示的列 -->
          <el-table-column prop="googleRevenue" label="收入" width="80" sortable class-name="highlight-column">
            <template slot-scope="scope">
              <span class="bold">
                ${{ scope.row.googleRevenue.toFixed(2) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="googleImpressions" label="展示次数" width="110" sortable></el-table-column>
          <el-table-column prop="googleClicks" label="点击次数" width="110" sortable></el-table-column>
          <el-table-column prop="googleCpc" label="每次点击费用" width="130" sortable>
            <template slot-scope="scope">
              ${{ scope.row.googleCpc.toFixed(2) }}
            </template>
          </el-table-column>

          <!-- 可展开的列 -->
          <template v-if="showAllGoogleColumns">
            <el-table-column prop="googleFunnelRequests" label="漏斗请求数" width="120" sortable></el-table-column>
            <el-table-column prop="googleFunnelImpressions" label="漏斗展示次数" width="130" sortable></el-table-column>
            <el-table-column prop="googleFunnelClicks" label="漏斗点击次数" width="130" sortable></el-table-column>
            <el-table-column prop="funnelShowRate" label="漏斗展示率" width="130" sortable>
               <template slot-scope="scope">
                {{ scope.row.funnelShowRate }}%
              </template>
            </el-table-column>
             <el-table-column prop="funnelClickRate" label="漏斗点击率" width="130" sortable>
              <template slot-scope="scope">
                {{ scope.row.funnelClickRate }}%
              </template>
             </el-table-column>
            <el-table-column prop="googleFunnelEcpm" label="漏斗ECPM" width="120" sortable>
              <template slot-scope="scope">
                ${{ scope.row.googleFunnelEcpm.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="googleRequests" label="广告请求数" width="130" sortable></el-table-column>
            <el-table-column prop="googleCoverage" label="覆盖率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.googleCoverage }}%
              </template>
            </el-table-column>
            <el-table-column prop="googleClickRate" label="点击率" width="90" sortable>
              <template slot-scope="scope">
                {{ scope.row.googleClickRate }}%
              </template>
            </el-table-column>
            <el-table-column prop="googleEcpm" label="ECPM" width="90" sortable>
              <template slot-scope="scope">
                ${{ scope.row.googleEcpm.toFixed(2) }}
              </template>
            </el-table-column>
          </template>
        </el-table-column>

        <!-- 关联指标列 -->
        <el-table-column label="关联指标">
          <el-table-column 
            prop="roi" 
            label="ROI" 
            sortable="custom"
            :sort-method="sortROI">
            <template slot-scope="scope">
              <div class="roi-cell" :class="getRoiClass(scope.row.roi)">
                {{ scope.row.roi }}%
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
  import moment from 'moment'
  import _ from 'lodash'

  export default {
    name: 'CombinedDashboard',
    data() {
      return {
        loading: false,
        dateRange: [new Date(), new Date()],
        searchQuery: [],
        belongToQuery: '',
        belongToOptions: [],
        tableData: [],
        pickerOptions: {
          shortcuts: [
            {
              text: '今天',
              onClick(picker) {
                const date = new Date()
                picker.$emit('pick', [date, date])
              }
            },
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '最近一月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 29)
                picker.$emit('pick', [start, end])
              }
            }
          ],
          disabledDate(time) {
            return time.getTime() > Date.now()
          }
        },
        pageSize: 10,
        currentPage: 1,
        total: 0,
        sortBy: '',
        sortOrder: '',
        showAllTTColumns: false,
        showAllGoogleColumns: false,
        isAdmin: false,
        lastUpdateTime: '',
        summaryData: {
          totalSpeed: 0,
          totalIncome: 0,
          avgROI: '0',
          updateTime: '-'
        },
        sidOptions: [],
      }
    },
    computed: {
      filteredTableData() {
        return this.tableData
      },
      totalCost() {
        console.log('计算总花费，当前tableData:', this.tableData)
        if (!Array.isArray(this.tableData)) return 0
        const total = this.tableData.reduce((sum, item) => {
          const cost = Number(item.ttCost) || 0
          console.log('单项花费:', cost)
          return sum + cost
        }, 0)
        console.log('总花费计算结果:', total)
        return total
      },
      totalRevenue() {
        console.log('计算总收入，当前tableData:', this.tableData)
        if (!Array.isArray(this.tableData)) return 0
        const total = this.tableData.reduce((sum, item) => {
          const revenue = Number(item.googleRevenue) || 0
          console.log('单项收入:', revenue)
          return sum + revenue
        }, 0)
        console.log('总收入计算结果:', total)
        return total
      },
      averageRoi() {
        console.log('计算ROI，总费:', this.totalCost, '总收入:', this.totalRevenue)
        if (!this.totalCost) return 0
        const roi = this.totalRevenue / this.totalCost
        console.log('ROI计算结果:', roi)
        return roi
      },
      totalGroups() {
        return Array.isArray(this.tableData) ? this.tableData.length : 0
      }
    },
    methods: {
      async fetchData() {
        if (!this.dateRange || !this.dateRange[0] || !this.dateRange[1]) return

        this.loading = true
        const params = {
          startDate: moment(this.dateRange[0]).format('YYYY-MM-DD'),
          endDate: moment(this.dateRange[1]).format('YYYY-MM-DD'),
          pageSize: this.pageSize,
          pageNum: this.currentPage,
          sid: this.searchQuery.join(','),
        }

        if (this.isAdmin) {
          params.belongTo = this.belongToQuery.join(',')
        }

        try {
          const [listResponse, totalResponse] = await Promise.all([
            this.$http.get('/launch/data/dash/board/list', { params }),
            this.$http.get('/launch/data/dash/board/total', {
              params: {
                startDate: params.startDate,
                endDate: params.endDate,
                sid: params.sid,
                belongTo: params.belongTo,
              }
            })
          ])

          // 处理列表数据
          const responseData = listResponse.data.body?.data || []
          this.total = listResponse.data.body?.total || 0
          this.tableData = responseData.map(item => {
            const tikTokResponse = item.tikTokResponse || {}
            const adsenseResponse = item.adsenseResponse || {}

            return {
              // 基本信息
              seriesName: item.seriesName || '',
              sid: item.sid || '',
              styleName: item.styleName || '',
              date: item.statTimeDay || '',
              belongTo: item.belongTo || '',

              // TT投放数据
              ttCost: Number(tikTokResponse.spend || 0),
              ttImpressions: Number(tikTokResponse.displayCount || 0),
              ttClicks: Number(tikTokResponse.clickCount || 0),
              ttConversions: Number(tikTokResponse.transformCount || 0),
              ttCpm: Number(tikTokResponse.thousandDisplaySpend || 0),
              ttCpc: Number(tikTokResponse.clickSpend || 0),
              ttCpa: Number(tikTokResponse.avgTransformSpend || 0),
              ttCtr: Number(tikTokResponse.clickRate || 0),
              ttCvr: Number(tikTokResponse.transformRate || 0),

              // Google变现数据
              googleRevenue: Number(adsenseResponse.income || 0),
              googleFunnelRequests: Number(adsenseResponse.funnelRequestCount || 0),
              googleFunnelImpressions: Number(adsenseResponse.funnelShowCount || 0),
              googleFunnelClicks: Number(adsenseResponse.funnelClickCount || 0),
              googleFunnelEcpm: Number(adsenseResponse.funnelECPM || 0),
              googleRequests: Number(adsenseResponse.requestCount || 0),
              googleImpressions: Number(adsenseResponse.showCount || 0),
              googleCoverage: Number(adsenseResponse.coverage || 0),
              googleClicks: Number(adsenseResponse.clickCount || 0),
              googleClickRate: Number(adsenseResponse.clickRate || 0),
              googleCpc: Number(adsenseResponse.perClickCost || 0),
              googleEcpm: Number(adsenseResponse.ecpm || 0),

              // 关联指标
              roi: Number(item.roi || 0),
              funnelShowRate: Number(item.funnelShowRate || 0),
              funnelClickRate: Number(item.funnelClickRate || 0)
            }
          })

          // 处理汇总数据
          if (totalResponse.data.header.code === 0 && totalResponse.data.body?.data) {
            const { updateTime, totalSpeed, totalIncome, avgROI } = totalResponse.data.body.data
            this.summaryData = {
              totalSpeed: Number(totalSpeed || 0),
              totalIncome: Number(totalIncome || 0),
              avgROI: avgROI || '0',
              updateTime: updateTime || '-'
            }
          }
        } catch (error) {
          console.error('获取数据失败:', error)
          this.$message.error('获取数据失败：' + error.message)
          // 出错时重置数据为默认值
          this.tableData = []
          this.total = 0
          this.summaryData = {
            totalSpeed: 0,
            totalIncome: 0,
            avgROI: '0',
            updateTime: '-'
          }
        } finally {
          this.loading = false
        }
      },
      async handleDateChange() {
        await this.fetchData()
      },
      formatPercentage(value) {
        if (typeof value !== 'number') return '0%'
        // 检查是否为整数
        if (Math.floor(value) === value) {
          return `${value}%`
        }
        return `${value.toFixed(2)}%`
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.currentPage = 1
        this.fetchData()
      },
      formatDate(date) {
        return moment(date).format('YYYY-MM-DD')
      },
      handleSortChange({ prop, order }) {
        this.sortBy = prop
        this.sortOrder = order

        // 对数据进行排序
        if (order) {
          this.tableData.sort((a, b) => {
            let aValue = a[prop]
            let bValue = b[prop]

            // 特殊处理ROI的排序
            if (prop === 'roi') {
              aValue = parseFloat(aValue) || 0
              bValue = parseFloat(bValue) || 0
            }

            if (order === 'ascending') {
              return aValue > bValue ? 1 : -1
            } else {
              return aValue < bValue ? 1 : -1
            }
          })
        }
      },
      toggleTTColumns() {
        this.showAllTTColumns = !this.showAllTTColumns
      },
      toggleGoogleColumns() {
        this.showAllGoogleColumns = !this.showAllGoogleColumns
      },
      // 修改搜索处理方法
      handleSearch: _.debounce(async function () {
        this.currentPage = 1
        await this.fetchData()
      }, 300),
      getRoiClass(roi) {
        const value = parseFloat(roi);
        if (value < 40) return 'roi-blue';
        if (value < 90) {
          if (value < 65) return 'roi-orange';  // 40-65 显示橙色
          return 'roi-yellow';  // 65-90 显示黄色
        }
        if (value < 150) return 'roi-light-green'; // 90-150 显示浅绿色
        return 'roi-green'; // 150以上显示深绿色
      },
      // 添加检查用户权限的方法
      async checkUserPermission() {
        try {
          const { data } = await this.$http.get('/launch/user/info')
          this.isAdmin = data.body.data.whetherAdmin === 1
        } catch (error) {
          console.error('获取用户权限失败:', error)
          this.isAdmin = false
        }
      },
      // 修改获取归属选项的方法
      async fetchBelongToOptions() {
        try {
          // 调用用户列表接口获取所有用户
          const { total } = await this.$store.dispatch('userManagement/fetchUsers', {
            pageNum: 1,
            pageSize: 999 // 获取足够大的数量以包含所有用户
          })

          // 从store中获取用户列表
          const users = this.$store.state.userManagement.users

          // 提取用户名作为归属选项
          this.belongToOptions = users.map(user => ({
            value: user.accountName,
            label: user.accountName
          }))
        } catch (error) {
          console.error('获取归属选项失败:', error)
          this.$message.error('获取归属选项失败：' + error.message)
        }
      },
      // 添加ROI排序方法
      sortROI(a, b) {
        // 移除百分号并转换为数字进行比较
        const roiA = parseFloat(a.roi) || 0;
        const roiB = parseFloat(b.roi) || 0;
        return roiA - roiB;
      },
      // 添加获取 SID 选项的方法
      async fetchSidOptions() {
        try {
          const response = await this.$http.get('/launch/data/group/list', {
            params: {
              pageSize: 999,
              pageNum: 1
            }
          })

          if (response.data.header.code === 0) {
            this.sidOptions = (response.data.body.data || []).map(item => ({
              value: item.sid,
              label: `${item.sid}`
            }))
          }
        } catch (error) {
          console.error('获取SID选项失败:', error)
          this.$message.error('获取SID选项失败：' + error.message)
        }
      },
    },
    watch: {
      searchQuery: {
        handler: 'handleSearch',
        immediate: false
      },
      belongToQuery: {
        handler: 'handleSearch',
        immediate: false
      }
    },
    created() {
      // 在组件创建时检查用户权限
      this.checkUserPermission()
      // 获取归属选项
      this.fetchBelongToOptions()
      // 获取 SID 选项
      this.fetchSidOptions()

      // 默认显示今天的数据
      const today = new Date()
      this.dateRange = [today, today]

      // 移除这里的直接调用，因为 dateRange 的设置会触发 watch
      this.fetchData()
    }
  }
</script>

<style scoped>
  .combined-dashboard {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 0;
  }

  .bold {
    font-weight: bolder;
  }

  /* 主卡片样式 */
  .combined-dashboard> :deep(.el-card) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  :deep(.el-card__body) {
    flex: 1;
    padding: 15px;
    min-height: 0;
  }

  /* 头部样式 */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-controls {
    display: flex;
    align-items: center;
  }

  /* 表格相关样式 */
  .series-name {
    display: inline-block;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :deep(.el-table) {
    height: auto;
  }

  :deep(.el-table th .cell) {
    display: flex;
    align-items: center;
  }

  /* 分页样式 */
  .pagination-container {
    margin: 20px 0;
    text-align: right;
  }

  /* 汇总部分样式优化 */
  .summary-section {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .summary-item {
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #f0f0f0;
  }

  .summary-item.update-time {
    border-right: none;
    justify-content: space-between;
  }

  .summary-item.update-time .summary-label {
    font-size: 13px;
    color: #909399;
  }

  .summary-item.update-time .summary-value.time {
    font-size: 12px;
    color: #8c8c8c;
  }

  .summary-label {
    font-size: 13px;
    color: #909399;
  }

  .summary-value {
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
  }

  .summary-value.cost {
    color: #ff4d4f;
  }

  .summary-value.revenue {
    color: #52c41a;
  }

  .summary-value.roi {
    color: #1890ff;
  }

  .summary-value.count {
    color: #722ed1;
  }

  /* 表格样式优化 */
  :deep(.el-table) {
    margin-top: 15px;
  }

  :deep(.el-table th.el-table__cell) {
    background-color: #fafafa;
    font-weight: 600;
    padding: 4px 0;
    height: 36px;
  }

  :deep(.el-table--border th.el-table__cell) {
    border-right: 1px solid #f0f0f0;
  }

  :deep(.el-table--border td.el-table__cell) {
    border-right: 1px solid #f0f0f0;
  }

  :deep(.el-table__header .cell) {
    line-height: 28px;
    height: 28px;
  }

  :deep(.el-table__header .cell .el-button) {
    padding: 0 3px;
    height: 28px;
    line-height: 28px;
  }

  /* 分页样式优化 */
  .pagination-container {
    margin: 16px 0 0;
    padding-top: 16px;
    border-top: 1px solid #f0f0f0;
  }

  /* 其他工具样式 */
  .el-date-editor.el-input {
    width: 220px;
  }

  .el-button [class^="el-icon-"] {
    margin-left: 0;
  }

  /* 滚动条样式 */
  :deep(.el-card__body::-webkit-scrollbar) {
    width: 6px;
  }

  :deep(.el-card__body::-webkit-scrollbar-thumb) {
    background: #dcdfe6;
    border-radius: 3px;
  }

  :deep(.el-card__body::-webkit-scrollbar-track) {
    background: #f5f7fa;
  }

  :deep(.highlight-column) {
    background-color: #f0f5ff !important;
  }

  :deep(.el-table__row:hover .highlight-column) {
    background-color: #f0f5ff !important;
  }

  .roi-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    height: 100%;
  }

  .roi-blue {
    background-color: #f0f5ff;
    color: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .roi-orange {
    background-color: #ffccc7;
    color: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .roi-yellow {
    background-color: #ffd666;
    color: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .roi-light-green {
    background-color: #b7eb8f;
    color: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  .roi-green {
    background-color: #52c41a;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }

  /* 为所有表格单元格添加内边距 */
  :deep(.el-table td.el-table__cell) {
    padding: 0;
    height: 40px;
  }

  :deep(.el-table .cell) {
    padding: 0 5px;
    /* 添加左右5px内边距 */
    height: 100%;
    display: flex;
    /* 使用flex布局实现水平垂直居中 */
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
  }

  /* ROI列保持无内边距 */
  :deep(.el-table td.el-table__cell:has(.roi-cell) .cell) {
    padding: 0;
  }

  /* 对于需要左对齐的列（如系列名称等），可以单独设置 */
  :deep(.el-table td.el-table__cell .cell .series-name) {
    justify-content: flex-start;
  }

  /* 确保悬停状态保持背景色 */
  :deep(.el-table__row:hover .roi-blue) {
    background-color: #f0f5ff;
  }

  :deep(.el-table__row:hover .roi-orange) {
    background-color: #ffccc7;
  }

  :deep(.el-table__row:hover .roi-yellow) {
    background-color: #ffd666;
  }

  :deep(.el-table__row:hover .roi-light-green) {
    background-color: #b7eb8f;
  }

  :deep(.el-table__row:hover .roi-green) {
    background-color: #52c41a;
  }

  /* 添加下拉框样式 */
  :deep(.el-select) {
    width: 200px;
  }

  :deep(.el-select .el-select__tags) {
    max-width: calc(100% - 30px);
  }
</style> 