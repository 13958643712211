import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import dataGroups from "./modules/dataGroups";
import dashboard from "./modules/dashboard";
import userManagement from "./modules/userManagement";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        dataGroups,
        dashboard,
        userManagement,
    },
});
