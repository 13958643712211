export default {
    namespaced: true,
    state: {
        users: [],
        loading: false,
        error: null,
        total: 0,
    },
    mutations: {
        SET_USERS(state, users) {
            state.users = users;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
        SET_TOTAL(state, total) {
            state.total = total;
        },
    },
    actions: {
        async fetchUsers({ commit }, params) {
            commit("SET_LOADING", true);
            try {
                const response = await this._vm.$http.post(
                    `/launch/account/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`
                );
                const users = response.data.body.data || [];
                const total = response.data.body.total || 0;
                commit("SET_USERS", users);
                commit("SET_TOTAL", total);
                return { data: users, total };
            } catch (error) {
                commit("SET_ERROR", error.message);
                throw (
                    error.response?.data?.msg ||
                    error.message ||
                    "获取用户列表失败"
                );
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async createUser({ commit, dispatch }, userData) {
            try {
                const response = await this._vm.$http.post(
                    `/launch/account/save?fullName=${encodeURIComponent(
                        userData.fullName
                    )}`
                );

                const { data } = response;
                if (data.header.code === 0) {
                    await dispatch("fetchUsers", {
                        pageNum: 1,
                        pageSize: 10,
                    });
                    return true;
                } else {
                    throw new Error(data.header.msg || "创建用户失败");
                }
            } catch (error) {
                throw (
                    error.response?.data?.msg || error.message || "创建用户失败"
                );
            }
        },
        async deleteUser({ commit, dispatch }, accountName) {
            try {
                const response = await this._vm.$http.post(
                    `/launch/account/remove?accountName=${encodeURIComponent(
                        accountName
                    )}`
                );

                const { data } = response;
                if (data.header.code === 0) {
                    await dispatch("fetchUsers", {
                        pageNum: 1,
                        pageSize: 10,
                    });
                    return true;
                } else {
                    throw new Error(data.header.msg || "删除用户失败");
                }
            } catch (error) {
                throw (
                    error.response?.data?.msg || error.message || "删除用户失败"
                );
            }
        },
        async getPassword({ commit }, accountName) {
            try {
                const response = await this._vm.$http.post(
                    `/launch/account/getPassword?accountName=${encodeURIComponent(
                        accountName
                    )}`
                );

                const { data } = response;
                if (data.header.code === 0) {
                    return data.body.data;
                } else {
                    throw new Error(data.header.msg || "获取密码失败");
                }
            } catch (error) {
                throw (
                    error.response?.data?.msg || error.message || "获取密码失败"
                );
            }
        },
    },
};
