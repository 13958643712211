<template>
  <el-container>
    <!-- 顶部导航栏 - 只在非登录页显示 -->
    <el-header height="60px" v-if="!isLoginPage">
      <div class="header-container">
        <!-- 左侧 Logo -->
        <div class="logo">
          AFS数据后台
        </div>
        
        <!-- 中间导航菜单 -->
        <el-menu 
          :default-active="$route.path"
          mode="horizontal" 
          background-color="#fff"
          text-color="#303133"
          active-text-color="#409EFF"
          router
        >
          <el-menu-item index="/dashboard/combined">
            <i class="el-icon-data-line"></i>
            数据看板
          </el-menu-item>
          
          <el-menu-item index="/data-groups">
            <i class="el-icon-folder"></i>
            数据组
          </el-menu-item>
          
          <el-menu-item index="/node-test">
            <i class="el-icon-monitor"></i>
            节点测试
          </el-menu-item>
          
          <!-- <el-menu-item index="/hot-words">
            <i class="el-icon-search"></i>
            热词搜索
          </el-menu-item> -->
          
          <el-menu-item index="/user-management" v-if="isAdmin">
            <i class="el-icon-user"></i>
            用户管理
          </el-menu-item>
        </el-menu>
        
        <!-- 右侧用户信息 -->
        <div class="header-right">
          <span v-if="username" class="username">{{ username }}</span>
          <el-button type="text" @click="handleLogout" v-if="isLoggedIn">
            退出登录
          </el-button>
        </div>
      </div>
    </el-header>

    <!-- 主要内容区域 -->
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
  export default {
    name: 'App',
    async created() {
      // 初始化时检查登录状态
      await this.$store.dispatch('user/checkLoginStatus')
    },
    computed: {
      isLoginPage() {
        return this.$route.path === '/login'
      },
      loading() {
        return this.$store.state.user.loading;
      },
      isLoggedIn() {
        return this.$store.state.user.isLoggedIn
      },
      isAdmin() {
        return this.$store.state.user.isAdmin
      },
      username() {
        return this.$store.state.user.username
      }
    },
    methods: {
      async handleLogout() {
        try {
          await this.$store.dispatch('user/logout')
          this.$message.success('退出成功')
          this.$router.push('/login')
        } catch (error) {
          this.$message.error(error.toString())
        }
      }
    }
  }
</script>

<style>

  /* 重置一些基础样式 */
  body {
    margin: 0;
    padding: 0;
    height: 100vh;
    background-color: #f5f7fa;
  }

  /* 头部样式 */
  .el-header {
    padding: 0;
    box-sizing: border-box;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .08);
    background-color: #fff;
    position: relative;
    z-index: 1000;
  }

  .header-container {
    width: 100%;
    margin: 0 auto;
    height: 60px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
  }

  /* Logo样式 */
  .logo {
    font-size: 20px;
    font-weight: bold;
    color: #409EFF;
    margin-right: 35px;
    white-space: nowrap;
  }

  /* 导航菜单样式优化 */
  .el-menu.el-menu--horizontal {
    border-bottom: none;
    flex: 1;
    display: flex;
  }

  .el-menu--horizontal>.el-menu-item {
    height: 60px;
    line-height: 60px;
    padding: 0 18px;
    font-size: 15px;
  }

  .el-menu--horizontal>.el-menu-item i {
    margin-right: 5px;
    font-size: 18px;
  }

  /* 右侧用户信息样式 */
  .header-right {
    display: flex;
    align-items: center;
    margin-left: 60px;
  }

  .username {
    margin-right: 15px;
    color: #606266;
    font-size: 14px;
  }

  /* 主要内容区域样式 */
  .el-main {
    padding: 20px;
    width: 100%;
    margin: 0 auto;
  }

  /* 响应式处理 */
  @media screen and (max-width: 768px) {
    .logo {
      margin-right: 30px;
    }

    .el-menu--horizontal>.el-menu-item {
      padding: 0 15px;
      font-size: 14px;
    }

    .header-container {
      padding: 0 20px;
    }

    .el-main {
      padding: 15px;
    }

    .username {
      display: none;
    }
  }

  /* 添加一个样式，让登录页面占满整个视口高度 */
  .el-container {
    min-height: 100vh;
  }
</style>
