import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import TTDashboard from "../views/dashboard/TTDashboard.vue";
import GoogleDashboard from "../views/dashboard/GoogleDashboard.vue";
import CombinedDashboard from "../views/dashboard/CombinedDashboard.vue";
import DataGroups from "../views/DataGroups.vue";
import UserManagement from "../views/UserManagement.vue";
import DataBoard from "@/views/DataBoard.vue";
import NodeTest from "@/views/NodeTest.vue";
import store from "@/store";
import HotWords from "@/views/HotWords.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { requiresAuth: false },
    },
    {
        path: "/dashboard/tt",
        name: "TTDashboard",
        component: TTDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/google",
        name: "GoogleDashboard",
        component: GoogleDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard/combined",
        name: "CombinedDashboard",
        component: CombinedDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/data-groups",
        name: "DataGroups",
        component: DataGroups,
        meta: { requiresAuth: true },
    },
    {
        path: "/node-test",
        name: "NodeTest",
        component: NodeTest,
        meta: { requiresAuth: true },
    },
    {
        path: "/user-management",
        name: "UserManagement",
        component: UserManagement,
        meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
        path: "/data-board",
        name: "DataBoard",
        component: DataBoard,
    },
    {
        path: "/hot-words",
        name: "HotWords",
        component: HotWords,
        meta: { requiresAuth: true },
    },
    {
        path: "/",
        redirect: "/dashboard/combined",
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.path === "/login") {
        next();
        return;
    }

    const isLoggedIn = await store.dispatch("user/checkLoginStatus");

    if (!isLoggedIn) {
        store.commit("dataGroups/CLEAR_DATA", null, { root: true });
        store.commit("user/CLEAR_USER_DATA");
        next("/login");
        return;
    }

    next();
});

export default router;
