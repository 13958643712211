<template>
  <div class="tt-dashboard">
    <el-card>
      <div slot="header" class="header">
        <span>TT投放数据</span>
        <div class="header-controls">
          <el-input
            v-model="searchQuery"
            placeholder="搜索系列名称"
            style="width: 200px; margin-right: 15px"
          ></el-input>
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="handleDateChange"
          ></el-date-picker>
        </div>
      </div>
      
      <el-table
        v-loading="loading"
        :data="filteredTableData"
        style="width: 100%"
        border>
        <el-table-column prop="seriesName" label="系列名称" width="300" fixed>
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.seriesName" placement="top">
              <span class="series-name">{{ scope.row.seriesName }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="cost" label="花费" width="120">
          <template slot-scope="scope">
            ${{ scope.row.cost.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="impressions" label="广告展示次数" width="140"></el-table-column>
        <el-table-column prop="clicks" label="广告点击次数" width="140"></el-table-column>
        <el-table-column prop="conversions" label="转化次数" width="120"></el-table-column>
        <el-table-column prop="cpm" label="千次展示费用" width="140">
          <template slot-scope="scope">
            ${{ scope.row.cpm.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="cpc" label="每次点击费用" width="140">
          <template slot-scope="scope">
            ${{ scope.row.cpc.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="cpa" label="平均转化费用" width="140">
          <template slot-scope="scope">
            ${{ scope.row.cpa.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="ctr" label="点击率" width="120">
          <template slot-scope="scope">
            {{ (scope.row.ctr * 100).toFixed(2) }}%
          </template>
        </el-table-column>
        <el-table-column prop="cvr" label="转化率" min-width="120">
          <template slot-scope="scope">
            {{ (scope.row.cvr * 100).toFixed(2) }}%
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="empty-text">暂无数据</div>
        </template>
      </el-table>

      <div class="summary-section" v-if="filteredTableData.length > 0">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-card shadow="hover">
              <div slot="header">总花费</div>
              <div class="summary-value">${{ totalCost.toFixed(2) }}</div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="hover">
              <div slot="header">总展示次数</div>
              <div class="summary-value">{{ totalImpressions }}</div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="hover">
              <div slot="header">总点击次数</div>
              <div class="summary-value">{{ totalClicks }}</div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="hover">
              <div slot="header">总转化次数</div>
              <div class="summary-value">{{ totalConversions }}</div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'TTDashboard',
    data() {
      return {
        loading: false,
        dateRange: [],
        searchQuery: '',
        tableData: [],
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '最近一月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              }
            }
          ]
        }
      }
    },
    computed: {
      filteredTableData() {
        if (!this.searchQuery) return this.tableData
        const query = this.searchQuery.toLowerCase()
        return this.tableData.filter(item =>
          item.seriesName.toLowerCase().includes(query)
        )
      },
      totalCost() {
        return this.filteredTableData.reduce((sum, item) => sum + item.cost, 0)
      },
      totalImpressions() {
        return this.filteredTableData.reduce((sum, item) => sum + item.impressions, 0)
      },
      totalClicks() {
        return this.filteredTableData.reduce((sum, item) => sum + item.clicks, 0)
      },
      totalConversions() {
        return this.filteredTableData.reduce((sum, item) => sum + item.conversions, 0)
      }
    },
    methods: {
      async fetchData() {
        if (!this.dateRange || !this.dateRange[0] || !this.dateRange[1]) return

        this.loading = true
        try {
          const response = await this.$http.get('/tt-data', {
            params: {
              startDate: moment(this.dateRange[0]).format('YYYY-MM-DD'),
              endDate: moment(this.dateRange[1]).format('YYYY-MM-DD')
            }
          })
          this.tableData = response.data
        } catch (error) {
          this.$message.error('获取数据失败：' + error.message)
        } finally {
          this.loading = false
        }
      },
      handleDateChange() {
        this.fetchData()
      }
    },
    created() {
      // 默认显示最近3天数据
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 2)
      this.dateRange = [start, end]
      this.fetchData()
    }
  }
</script>

<style scoped>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-controls {
    display: flex;
    align-items: center;
  }

  .series-name {
    display: inline-block;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .summary-section {
    margin-top: 20px;
    padding: 20px 0;
  }

  .summary-value {
    font-size: 24px;
    font-weight: bold;
    color: #409EFF;
    text-align: center;
    margin-top: 10px;
  }

  .empty-text {
    text-align: center;
    color: #909399;
    padding: 30px 0;
  }
</style> 