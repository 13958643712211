import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css";
import axios from "axios";

Vue.use(ElementUI);
Vue.config.productionTip = false;

// 设置axios拦截器
axios.interceptors.request.use((config) => {
    const token = store.state.user.token;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            store.dispatch("user/logout");
            router.push("/login");
        }
        return Promise.reject(error);
    }
);

// 根据环境设置 baseURL
const baseURL =
    process.env.NODE_ENV === "production" ? location.origin : "/api";

// 创建 axios 实例
const http = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    timeout: 5000,
});

// 添加到 Vue 原型链
Vue.prototype.$http = http;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
