<template>
  <div class="hot-words">
    <el-card>
      <div slot="header">
        <span>热词搜索</span>
      </div>
      
      <div class="search-form">
        <el-form :inline="true" :model="searchForm">
          <el-form-item label="区域">
            <el-select 
              v-model="searchForm.geo" 
              placeholder="请选择区域"
              clearable
              filterable
              :filter-method="filterGeo"
              :default-first-option="true"
            >
              <el-option
                v-for="(item, index) in filteredGeoOptions"
                :key="`geo-${item.value}-${index}`"
                :label="item.label"
                :value="item.value"
              >
                <span>{{ item.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类">
            <el-select 
              v-model="searchForm.category" 
              placeholder="请选择分类"
              clearable
              filterable
              :filter-method="filterCategory"
              :default-first-option="true"
            >
              <el-option
                v-for="(item, index) in filteredCategoryOptions"
                :key="`category-${item.value}-${index}`"
                :label="item.label"
                :value="item.value"
              >
                <span>{{ item.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="渠道">
            <el-select 
              v-model="searchForm.channel" 
              placeholder="请选择渠道"
              clearable
            >
              <el-option
                v-for="(item, index) in channelOptions"
                :key="`channel-${item.value}-${index}`"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键词">
            <el-select
              v-model="searchForm.keyword"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteSearch"
              :loading="loading"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="`keyword-${item.mid}`"
                :label="item.keyword"
                :value="item.keyword"
              >
                <div class="option-item">
                  <span class="keyword">{{ item.keyword }}</span>
                  <span class="type">{{ item.searchCount }}</span>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间范围">
            <el-select 
              v-model="searchForm.dateRangeType"
              placeholder="请选择时间范围"
              style="width: 150px"
            >
              <el-option value="now 1-H" label="过去1小时"></el-option>
              <el-option value="now 4-H" label="过去4小时"></el-option>
              <el-option value="now 1-d" label="过去1天"></el-option>
              <el-option value="now 7-d" label="过去7天"></el-option>
              <el-option value="today 1-m" label="过去30天"></el-option>
              <el-option value="today 3-m" label="过去90天"></el-option>
              <el-option value="" label="过去12个月"></el-option>
              <el-option value="today 5-y" label="过去5年"></el-option>
              <el-option value="all" label="2004至今"></el-option>
              <el-option value="custom" label="自定义"></el-option>
            </el-select>
            
            <el-date-picker
              v-if="searchForm.dateRangeType === 'custom'"
              v-model="searchForm.dateRange"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              style="width: 320px; margin-left: 10px"
              @change="handleDateChange"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button 
              type="primary" 
              @click="handleSearch"
              :loading="loading"
            >搜索</el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          prop="keyword"
          label="关键词"
        ></el-table-column>
        <el-table-column
          prop="searchCount"
          label="搜索次数"
          sortable
        ></el-table-column>
        <el-table-column
          prop="updateTime"
          label="更新时间"
          sortable
        ></el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
    export default {
        name: 'HotWords',
        data() {
            return {
                loading: false,
                searchForm: {
                    keyword: '',
                    geo: '',
                    dateRange: '',
                    dateRangeType: 'today 1-m',
                    category: '',
                    channel: ''
                },
                geoOptions: [],
                filteredGeoOptions: [],
                geoSearchKeyword: '',
                options: [],
                tableData: [],
                currentPage: 1,
                pageSize: 10,
                total: 0,
                pickerOptions: {
                    shortcuts: [{
                        text: '过去1小时',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000);
                            picker.$emit('pick', [start, end], 'now 1-H');
                        }
                    }, {
                        text: '过去4小时',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 4);
                            picker.$emit('pick', [start, end], 'now 4-H');
                        }
                    }, {
                        text: '过去1天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [start, end], 'now 1-d');
                        }
                    }, {
                        text: '过去7天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end], 'now 7-d');
                        }
                    }, {
                        text: '过去30天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end], 'today 1-m');
                        }
                    }, {
                        text: '过去90天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end], 'today 3-m');
                        }
                    }, {
                        text: '过去12个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setMonth(start.getMonth() - 12);
                            picker.$emit('pick', [start, end], '');
                        }
                    }, {
                        text: '过去5年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setFullYear(start.getFullYear() - 5);
                            picker.$emit('pick', [start, end], 'today 5-y');
                        }
                    }, {
                        text: '2004至今',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date('2004-01-01');
                            picker.$emit('pick', [start, end], 'all');
                        }
                    }]
                },
                categoryOptions: [],
                filteredCategoryOptions: [],
                channelOptions: [
                    {
                        label: 'Google网页搜索',
                        value: ''
                    },
                    {
                        label: 'Google新闻搜索',
                        value: 'news'
                    },
                    {
                        label: 'Google购物',
                        value: 'froogle'
                    },
                    {
                        label: 'Google图片搜索',
                        value: 'images'
                    },
                    {
                        label: 'YouTube搜索',
                        value: 'youtube'
                    },
                ]
            }
        },
        methods: {
            async handleSearch() {
                this.currentPage = 1;
                this.loading = true;
                try {
                    const requestData = {
                        comparisonItem: [
                            {
                                keyword: this.searchForm.keyword,
                                geo: this.searchForm.geo || '',
                                time: this.searchForm.dateRangeType
                            }
                        ],
                        category: this.searchForm.category || 0,
                        property: this.searchForm.channel || ''
                    };

                    const response = await this.$http.post('http://localhost:8099/node/hotword', requestData, {
                        timeout: 20000  // 设置超时时间为20秒
                    });

                    if (response.data.success && Array.isArray(response.data.data)) {
                        this.tableData = response.data.data;
                        this.total = response.data.total || response.data.data.length;
                    } else {
                        this.tableData = [];
                        this.total = 0;
                    }
                } catch (error) {
                    this.$message.error(error.message || '获取数据失败');
                } finally {
                    this.loading = false;
                }
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.handleSearch();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.handleSearch();
            },
            async remoteSearch(query) {
                if (query !== '') {
                    this.loading = true;
                    try {
                        const response = await this.$http.get(`http://localhost:8099/node/search`, {
                            params: {
                                keyword: query,
                                geo: this.searchForm.geo
                            }
                        });

                        if (response.data.success && Array.isArray(response.data.data)) {
                            this.options = response.data.data.map(item => ({
                                keyword: item.title,
                                searchCount: item.type,
                                mid: item.mid
                            }));

                            this.options.unshift({
                                keyword: query,
                                searchCount: "关键词",
                                mid: query
                            });
                        } else {
                            this.options = [];
                        }
                    } catch (error) {
                        this.$message.error(error.message || '获取搜索建议失败');
                    } finally {
                        this.loading = false;
                    }
                } else {
                    this.options = [];
                }
            },
            filterGeo(query) {
                if (query !== '') {
                    const lowercaseQuery = query.toLowerCase();
                    this.filteredGeoOptions = this.geoOptions.filter(item =>
                        item.label.toLowerCase().includes(lowercaseQuery)
                    );
                } else {
                    this.filteredGeoOptions = this.geoOptions;
                }
            },
            async fetchGeoData() {
                try {
                    const response = await this.$http.get('http://localhost:8099/node/geo');
                    if (response.data.success && response.data.data) {
                        this.geoOptions = [];
                        this.geoOptions.push({
                            label: '全球',
                            value: ''
                        });

                        const { children } = response.data.data;
                        children.forEach(country => {
                            this.geoOptions.push({
                                label: country.name,
                                value: country.id
                            });

                            if (country.children && country.children.length) {
                                country.children.forEach(region => {
                                    this.geoOptions.push({
                                        label: `${country.name} > ${region.name}`,
                                        value: `${country.id}-${region.id}`
                                    });
                                });
                            }
                        });
                        this.filteredGeoOptions = [...this.geoOptions];
                    }
                } catch (error) {
                    this.$message.error('获取区域数据失败');
                }
            },
            handleDateChange(val) {
                if (val) {
                    this.searchForm.dateRangeType = `${val[0]} ${val[1]}`;
                } else {
                    this.searchForm.dateRangeType = 'custom';
                }
            },
            filterCategory(query) {
                if (query !== '') {
                    const lowercaseQuery = query.toLowerCase();
                    this.filteredCategoryOptions = this.categoryOptions.filter(item =>
                        item.label.toLowerCase().includes(lowercaseQuery)
                    );
                } else {
                    this.filteredCategoryOptions = this.categoryOptions;
                }
            },
            async fetchCategoryData() {
                try {
                    const response = await this.$http.get('http://localhost:8099/node/category');
                    if (response.data.success && response.data.data) {
                        this.categoryOptions = [];
                        this.categoryOptions.push({
                            label: '全部',
                            value: ''
                        });

                        const { children } = response.data.data;
                        children.forEach(category => {
                            this.categoryOptions.push({
                                label: category.name,
                                value: category.id
                            });

                            if (category.children && category.children.length) {
                                category.children.forEach(subCategory => {
                                    this.categoryOptions.push({
                                        label: `${category.name} > ${subCategory.name}`,
                                        value: subCategory.id
                                    });
                                });
                            }
                        });
                        this.filteredCategoryOptions = [...this.categoryOptions];
                    }
                } catch (error) {
                    this.$message.error('获取分类数据失败');
                }
            }
        },
        created() {
            this.fetchGeoData();
            this.fetchCategoryData();
        }
    }
</script>

<style scoped>
    .hot-words {
        min-height: 100%;
    }

    .search-form {
        margin-bottom: 20px;
    }

    .pagination-container {
        margin-top: 20px;
        text-align: right;
    }

    :deep(.el-form-item) {
        margin-bottom: 0;
    }

    :deep(.el-select) {
        width: 200px;
    }

    .option-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .option-item .keyword {
        color: #606266;
    }

    .option-item .type {
        color: #8492a6;
        font-size: 13px;
    }

    :deep(.el-select-dropdown__item) {
        padding: 0 20px;
    }

    .keyword-select:deep(.el-select) {
        width: 350px;
    }

    :deep(.el-date-editor.el-input) {
        width: 320px;
    }

    .el-date-picker {
        margin-left: 10px;
    }
</style> 