export default {
    namespaced: true,
    state: {
        ttData: [],
        googleData: [],
        combinedData: [],
        loading: false,
        error: null,
    },
    mutations: {
        SET_TT_DATA(state, data) {
            state.ttData = data;
        },
        SET_GOOGLE_DATA(state, data) {
            state.googleData = data;
        },
        SET_COMBINED_DATA(state, data) {
            state.combinedData = data;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
    },
    actions: {
        async fetchTTData({ commit }, dateRange) {
            commit("SET_LOADING", true);
            try {
                const response = await this._vm.$http.get("/tt-data", {
                    params: dateRange,
                });
                commit("SET_TT_DATA", response.data);
                return response.data;
            } catch (error) {
                commit("SET_ERROR", error.message);
                throw error;
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async fetchGoogleData({ commit }, dateRange) {
            commit("SET_LOADING", true);
            try {
                const response = await this._vm.$http.get("/google-data", {
                    params: dateRange,
                });
                commit("SET_GOOGLE_DATA", response.data);
                return response.data;
            } catch (error) {
                commit("SET_ERROR", error.message);
                throw error;
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async fetchCombinedData({ commit }, dateRange) {
            commit("SET_LOADING", true);
            try {
                const response = await this._vm.$http.get("/combined-data", {
                    params: dateRange,
                });
                commit("SET_COMBINED_DATA", response.data);
                return response.data;
            } catch (error) {
                commit("SET_ERROR", error.message);
                throw error;
            } finally {
                commit("SET_LOADING", false);
            }
        },
    },
};
