<template>
  <div class="google-dashboard">
    <el-card>
      <div slot="header" class="header">
        <span>Google变现数据</span>
        <div class="header-controls">
          <el-input
            v-model="searchQuery"
            placeholder="搜索SID"
            style="width: 200px; margin-right: 15px"
          ></el-input>
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="handleDateChange"
          ></el-date-picker>
        </div>
      </div>
      
      <el-table
        v-loading="loading"
        :data="filteredTableData"
        style="width: 100%"
        border>
        <el-table-column prop="sid" label="SID" width="180" fixed></el-table-column>
        <el-table-column prop="revenue" label="收入" width="120">
          <template slot-scope="scope">
            ${{ scope.row.revenue.toFixed(2) }}
          </template>
        </el-table-column>
        
        <!-- 漏斗数据 -->
        <el-table-column label="漏斗数据" align="center">
          <el-table-column prop="funnelRequests" label="请求数" width="120"></el-table-column>
          <el-table-column prop="funnelImpressions" label="展示次数" width="120"></el-table-column>
          <el-table-column prop="funnelClicks" label="点击次数" width="120"></el-table-column>
          <el-table-column prop="funnelEcpm" label="ECPM" width="120">
            <template slot-scope="scope">
              ${{ scope.row.funnelEcpm.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="funnelShowRate" label="展示率" width="120">
            <template slot-scope="scope">
              {{ (scope.row.funnelShowRate * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column prop="funnelClickRate" label="点击率" width="120">
            <template slot-scope="scope">
              {{ (scope.row.funnelClickRate * 100).toFixed(2) }}%
            </template>
          </el-table-column>
        </el-table-column>

        <!-- 广告数据 -->
        <el-table-column label="广告数据" align="center">
          <el-table-column prop="adRequests" label="请求数" width="120"></el-table-column>
          <el-table-column prop="adImpressions" label="展示次数" width="120"></el-table-column>
          <el-table-column prop="coverage" label="覆盖率" width="120">
            <template slot-scope="scope">
              {{ (scope.row.coverage * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column prop="adClicks" label="点击次数" width="120"></el-table-column>
          <el-table-column prop="adCtr" label="点击率" width="120">
            <template slot-scope="scope">
              {{ (scope.row.adCtr * 100).toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column prop="cpc" label="CPC" width="120">
            <template slot-scope="scope">
              ${{ scope.row.cpc.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="ecpm" label="ECPM" width="120">
            <template slot-scope="scope">
              ${{ scope.row.ecpm.toFixed(2) }}
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'GoogleDashboard',
    data() {
      return {
        loading: false,
        dateRange: [],
        searchQuery: '',
        tableData: [],
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '最近一月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              }
            }
          ]
        }
      }
    },
    computed: {
      filteredTableData() {
        if (!this.searchQuery) return this.tableData
        const query = this.searchQuery.toLowerCase()
        return this.tableData.filter(item =>
          item.sid.toString().includes(query)
        )
      }
    },
    methods: {
      async fetchData() {
        if (!this.dateRange || !this.dateRange[0] || !this.dateRange[1]) return

        this.loading = true
        try {
          const response = await this.$http.get('/google-data', {
            params: {
              startDate: moment(this.dateRange[0]).format('YYYY-MM-DD'),
              endDate: moment(this.dateRange[1]).format('YYYY-MM-DD')
            }
          })
          this.tableData = response.data
        } catch (error) {
          this.$message.error('获取数据失败：' + error.message)
        } finally {
          this.loading = false
        }
      },
      handleDateChange() {
        this.fetchData()
      }
    },
    created() {
      // 默认显示最近7天数据
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
      this.dateRange = [start, end]
      this.fetchData()
    }
  }
</script>

<style scoped>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-controls {
    display: flex;
    align-items: center;
  }
</style> 