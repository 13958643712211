<template>
  <div class="login-container">
    <el-card class="login-card">
      <h2>登录</h2>
      <el-form :model="loginForm" :rules="rules" ref="loginForm">
        <el-form-item prop="username">
          <el-input 
            v-model="loginForm.username" 
            placeholder="用户名"
            @keyup.enter.native="handleLogin">
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input 
            v-model="loginForm.password" 
            type="password" 
            placeholder="密码"
            @keyup.enter.native="handleLogin">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button 
            type="primary" 
            @click="handleLogin" 
            :loading="loading" 
            style="width: 100%">
            登录
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  export default {
    name: 'Login',
    data() {
      return {
        loginForm: {
          username: '',
          password: ''
        },
        loading: false,
        rules: {
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      async handleLogin() {
        try {
          await this.$refs.loginForm.validate()
          this.loading = true

          await this.$store.dispatch('user/login', {
            username: this.loginForm.username,
            password: this.loginForm.password
          })

          this.$message.success('登录成功')
          this.$router.push('/dashboard/combined')
        } catch (error) {
          this.$message.error(error.toString())
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

<style scoped>
  .login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-card {
    width: 400px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
</style> 