<template>
  <div class="data-board">
    <!-- 搜索表单 -->
    <div class="search-form">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="开始日期">
          <el-date-picker
            v-model="searchForm.startDate"
            type="date"
            placeholder="选择开始日期"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker
            v-model="searchForm.endDate"
            type="date"
            placeholder="选择结束日期"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="广告位ID">
          <el-input v-model="searchForm.sid" placeholder="请输入广告位ID" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 数据表格 -->
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="sid" label="广告位ID" width="180" />
      <el-table-column prop="seriesName" label="系列名称" />
      
      <!-- TikTok数据 -->
      <el-table-column label="TikTok数据">
        <el-table-column prop="tikTokResponse.displayCount" label="展示次数" />
        <el-table-column prop="tikTokResponse.clickCount" label="点击次数" />
        <el-table-column prop="tikTokResponse.transformCount" label="转化次数" />
        <el-table-column prop="tikTokResponse.clickRate" label="点击率">
          <template #default="scope">
            {{ (scope.row.tikTokResponse.clickRate * 100).toFixed(2) }}%
          </template>
        </el-table-column>
      </el-table-column>

      <!-- Adsense数据 -->
      <el-table-column label="Adsense数据">
        <el-table-column prop="adsenseResponse.showCount" label="展示次数" />
        <el-table-column prop="adsenseResponse.clickCount" label="点击次数" />
        <el-table-column prop="adsenseResponse.income" label="收入" />
        <el-table-column prop="adsenseResponse.ecpm" label="eCPM" />
      </el-table-column>

      <el-table-column prop="roi" label="ROI" />
      <el-table-column prop="funnelShowRate" label="漏斗展示率" />
      <el-table-column prop="funnelClickRate" label="漏斗点击率" />
    </el-table>

    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :total="total"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue'
    import axios from 'axios'

    // 数据状态
    const tableData = ref([])
    const total = ref(0)
    const currentPage = ref(1)
    const pageSize = ref(10)

    // 搜索表单
    const searchForm = reactive({
        startDate: '',
        endDate: '',
        sid: '',
        accountName: 'admin' // 固定值
    })

    // 获取数据的方法
    const fetchData = async () => {
        try {
            const response = await this.$http.get('/launch/data/dash/board/list', {
                params: {
                    pageNum: currentPage.value,
                    pageSize: pageSize.value,
                    startDate: searchForm.startDate,
                    endDate: searchForm.endDate,
                    sid: searchForm.sid,
                    accountName: searchForm.accountName
                }
            })

            if (response.data.header.code === 0) {
                tableData.value = response.data.body.data
                total.value = response.data.body.total
            } else {
                ElMessage.error('获取数据失败：' + response.data.header.msg)
            }
        } catch (error) {
            ElMessage.error('请求失败：' + error.message)
        }
    }

    // 分页方法
    const handleSizeChange = (val) => {
        pageSize.value = val
        fetchData()
    }

    const handleCurrentChange = (val) => {
        currentPage.value = val
        fetchData()
    }

    // 组件挂载时获取数据
    onMounted(() => {
        fetchData()
    })
</script>

<style scoped>
    .data-board {
        padding: 20px;
    }

    .search-form {
        margin-bottom: 20px;
    }

    .pagination {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
    }
</style> 